import "../../styles/highlight.css";
import { HighlightProps } from "../types";

export const Highlight = ({
  src,
  title,
  description,
  component,
  background,
  style, 
  svg
}: HighlightProps) => {
  const styleHighlight = {
    background: background ? background : "",
  };
  return (
    <div style={styleHighlight} className="highlight-wrapper">
      <div className="highlight-container">
        <div className="highlight-content-wrapper">
          <div className="highlight-content-title">
            <h2>{title}</h2>
          </div>
          <div style={style} className="highlight-content-subtitle">
            <p>{description}</p>
            {component}
          </div>
        </div>
        <div className="highlight-icon">
          {src ? <img src={src} alt={title}></img> : svg}
        </div>
      </div>
    </div>
  );
};
