import React, { FormEvent } from "react";
import "../../styles/signupForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const NewsLetterForm = () => {
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // This line prevents the default form action
    const form = event.currentTarget;
    const formData = new FormData(event.currentTarget);
    const formDataObj = Object.fromEntries(formData);

    const formBody = new URLSearchParams(formDataObj as any).toString();

    try {
      const response = await fetch(
        "https://docs.google.com/forms/d/e/1FAIpQLSdQfjRwYDTPYdW0nOWQPznkL3YTYrVZZHYRM244v2s8cynOkQ/formResponse",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );

      toast.success(
        "Success! Thank you for signing up for our newsletters!",
        {}
      );
      // clear the form
      form.reset();
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Submission failed. Please try again.");
    }
  };

  return (
    <div className="form-newsletter-wrapper">
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLSdQfjRwYDTPYdW0nOWQPznkL3YTYrVZZHYRM244v2s8cynOkQ/formResponse"
        method="POST"
        className="form-newsletter"
        onSubmit={handleSubmit}
      >
        <div className="newsletter-input">
          <input
            placeholder="Enter your email"
            type="text"
            name="entry.535773137"
            id="entry.535773137"
            required
          />
          <button type="submit">Subscribe</button>
        </div>
        <div id="newsletter-term">
          <p>
            By clicking Sign Up you're confirming that you agree with our{" "}
            <Link
              to="/policies/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>
            .
          </p>{" "}
        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default NewsLetterForm;
