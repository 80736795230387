import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./pages/Home";
import TradeCredit from "./pages/more/trade-credit";
import TreasuryManagement from "./pages/more/treasury-management";
import Contact from "./pages/contact";
import Solution from "./pages/Solution";
import More from "./pages/more";
import "./styles/global.css";
import JoinBetaForm from "./pages/form/join-beta";
import Policies, {
  CookiePolicy,
  PrivacyPolicy,
  TermsAndConditions,
} from "./pages/policies";
import JoinBeta from "./pages/join-beta";
import { CEO } from "./components/BlogContents/CEO";
import CoreValue from "./components/BlogContents/CoreValue";
import Blogs from "./pages/blogs";
import Resources from "./pages/resources";
// import Automation from "./components/BlogContents/Automation";
// import BusinessStrategies from "./components/BlogContents/BusinessStrategies";
// import FinancialNews from './components/BlogContents/FinancialNews';
// import FinancialStrategies from "./components/BlogContents/FinancialStrategies";
// import FutureTech from "./components/BlogContents/FutureTech";
// import Manufacturing from "./components/BlogContents/Manufacturing";
// import StartupAdvice from "./components/BlogContents/StartupAdvice";
// import TaxTips from "./components/BlogContents/TaxTips";
import Company from "./pages/company";
// import FinancialLiteracy from './components/BlogContents/FinancialLiteracy';



const App: React.FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/more" element={<More />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/company" element={<Company />} />
        <Route path="/resources" element={<Resources />}>
        </Route>
        <Route path="/join-beta" element={<JoinBeta />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service/trade-credit" element={<TradeCredit />} />
        <Route
          path="/service/treasury-management"
          element={<TreasuryManagement />}
        />
        <Route path="/form/join-beta" element={<JoinBetaForm />} />
        <Route path="/policies" element={<Policies />}>
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
        </Route>
        <Route path="/blogs/:contentId" element={<Blogs />}>
          <Route path="ceo-letter" element={<CEO />} />
          <Route path="core-value" element={<CoreValue />} />
          
          {/* <Route path="automation" element={<Automation />} />
          <Route path="business-strategies" element={<BusinessStrategies />} />
          <Route path="financial-news" element={<FinancialNews />} />
          <Route path="financial-strategies" element={<FinancialStrategies />} />
          <Route path="future-tech" element={<FutureTech />} />
          <Route path="manufacturing" element={<Manufacturing />} />
          <Route path="startup-advice" element={<StartupAdvice />} />
          <Route path="tax-tips" element={<TaxTips />} />
          <Route path="financial-literacy" element={<FinancialLiteracy />} /> */}

        </Route>
      </Routes>
    </HashRouter>
  );
};

export default App;
