import { useState } from "react";
import { IoIosArrowUp , IoIosArrowDown} from "react-icons/io";
import { FiMinus, FiPlus } from "react-icons/fi";

interface FAQItemProps {
  question: string;
  answer: string;
}

export const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFaq = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <div className="faq-question" onClick={toggleFaq}>
        <span className="faq-toggle">{isOpen ? <FiMinus style={{color:"#D3AF37", fontSize: "24px" }}/> : <FiPlus style={{fontSize: "24px", color: "#58595B"}}/>}</span>
        <h6>{question}</h6>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};
