import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import styles from "../../styles/service.module.css";

export default function More() {
  return (
    <div>
      <Navbar />
      <div className={styles.allServiceContainer}>
        <div className={styles.allServiceWrapper}>
        </div>
      </div>
      <Footer />
    </div>
  );
}
