import BaseButton from "../Buttons/BaseButton";
import "../../styles/maxEfficenty.css";
import JoinBetaPopup from "../Form/JoinBetaPopup";
import { useState } from "react";

interface MaxEfficentyProps {
  style?: React.CSSProperties;
  buttonColor: string;
  buttonTextColor?: string;
  background?: string;
}

const MaxEfficenty: React.FC<MaxEfficentyProps> = ({
  style,
  buttonColor,
  buttonTextColor,
  background
}) => {
  const [showJoinBetaPopup, setJoinBetaPopup] = useState(false);

  const togglePopup = () => {
    setJoinBetaPopup(!showJoinBetaPopup);
  };
  return (
    <div className="maxEfficenty-container" style={{background: background}}>
      <div className="maxEfficenty-wrapper">
        <div className="maxEfficenty-header-wrapper">
          <div className="maxEfficenty-header-title">
            <h3>Experience CashZar's power first-hand</h3>
          </div>
          <div style={style} className="maxEfficenty-header-description">
            <p>
              Schedule a personalized demo to see how CashZar can meet your
              needs
            </p>
          </div>
        </div>
        <div className="maxEfficenty-buttons">
          <BaseButton
            text="Join Beta"
            background={buttonColor}
            color={buttonTextColor}
            onClick={togglePopup}
          />
        </div>
        <JoinBetaPopup show={showJoinBetaPopup} onClose={togglePopup} />
      </div>
    </div>
  );
};

export default MaxEfficenty;
