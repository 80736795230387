import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";
import TermsAndConditionsContent from "../../components/Policies/TermsAndConditionsContent";
import "../../styles/policies.css";
import PrivacyPolicyContent from "../../components/Policies/PrivacyPolicyContent";
import CookiePolicyContent from "../../components/Policies/CookiePolicyContent";
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function Policies() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If the user navigates directly to '/policies', redirect to '/policies/terms-and-conditions'
    if (
      location.pathname === "/policies" ||
      location.pathname === "/policies/"
    ) {
      navigate("/policies/terms-and-conditions");
    }
  }, [location, navigate]);

  return (
    <div>
      <Navbar />
      <div className="policy-wrapper">
        
        <nav className="policy-navbar">
          <NavLink
            to="/policies/terms-and-conditions"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Terms & Conditions
          </NavLink>
          <NavLink
            to="/policies/privacy-policy"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to="/policies/cookie-policy"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Cookie Policy
          </NavLink>
        </nav>
        <div className="policy-content">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export function TermsAndConditions() {
  return (
    <div>
      <TermsAndConditionsContent />
    </div>
  );
}

export function PrivacyPolicy() {
  return (
    <div>
      <PrivacyPolicyContent />
    </div>
  );
}

export function CookiePolicy() {
  return (
    <div>
      <CookiePolicyContent />
    </div>
  );
}
