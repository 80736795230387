import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import "../../styles/contact.css";
import contactBackground from "../../images/contact.png";
import { SVGs } from "../../assets/app-svg";
import { toast, ToastContainer } from "react-toastify";
import { FormEvent } from "react";
import { address } from '../../assets/app-data';

const Contact = () => {
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // This line prevents the default form action
    const form = event.currentTarget;
    const formData = new FormData(event.currentTarget);
    const formDataObj = Object.fromEntries(formData);

    const formBody = new URLSearchParams(formDataObj as any).toString();

    try {
      const response = await fetch(
        "https://docs.google.com/forms/d/e/1FAIpQLSf0NzVMUg5pN_2k9jlOBGbbyY-UzZT8y9-8WgRYV50d7DBR-Q/formResponse",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );

      toast.success(
        "Success! Your feedback is important to us and has been successfully sent. Thank you for sharing your thoughts!",
        {}
      );
      // clear the form
      form.reset();
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Submission failed. Please try again.");
    }
  };

  
  return (
    <div>
     
      <Navbar />
      <div
        className="contact-page-headerland"
        style={{
          backgroundImage: `url(${contactBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="contact-page-headerland-header">
        <h2>Get In Touch</h2>
        <p>
          Whether you have questions about our cutting-edge algorithms, need
          assistance navigating our platform, or seek personalize financial
          insights, our dedicated team is ready to assist you
        </p>
        </div>
        <div
        className="contact-feedback-popup-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="feedback-content-wrapper">
          <div className="feedback-popup-header">
            <h4>Send us a Message</h4>
            {SVGs.contact}
          </div>
          <form
            className="feedback-form"
            action="https://docs.google.com/forms/d/e/1FAIpQLSf0NzVMUg5pN_2k9jlOBGbbyY-UzZT8y9-8WgRYV50d7DBR-Q/formResponse"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="feedback-form-item">
              <label htmlFor="entry.746084709">Your Name:</label>
              <input
                type="text"
                name="entry.746084709"
                id="entry.746084709"
                required
              ></input>
            </div>
            <div className="feedback-form-item">
              <label htmlFor="entry.1617225908">E-mail Address:</label>
              <input
                type="text"
                name="entry.1617225908"
                id="entry.1617225908"
                required
              ></input>
            </div>
            <div className="feed-back-message">
              <div className="feed-back-message-text">
                <label id="entry.1807301769" htmlFor="entry.1807301769">
                  Message
                </label>
                <textarea name="entry.1807301769" required></textarea>
              </div>
              <div className="feed-back-message-button">
                <button type="submit">Send</button>
              </div>
            </div>
          </form>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <div className="contact-info-wrapper">
          {/* <button className="feedback-popup-close" onClick={onClose}>
            &times;
          </button> */}
          <div className="contact-info-container">
            <h4>Contact Information</h4>
            <div id="feedback-address" className="contact-info-item">
              <div style={{width: "47px"}}>{SVGs.location_orang}</div>
              <p>{address}</p>
            </div>
            <div className="contact-info-item">
              <div style={{width: "47px"}}>{SVGs.letter_orange}</div>
              <p>contact@cashzar.com</p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="contact-page-white-space"></div>
      <Footer />
    </div>
  );
};

export default Contact;
