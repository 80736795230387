import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import MaxEfficenty from "../../components/MaxEfficenty";
import HeaderLand from "../../components/HeaderLand";
import { ourSolutionHeaderData } from "../../assets/app-data";
import "../../styles/solution.css";
import services from "../../assets/services.json";
import { ServiceCard } from "../../components/ServiceCard";
import FAQList from "../../components/FAQ";
import faqs from "../../assets/faqs.json";
import { ScrollToTopButton } from "../../components/ScrollToTop";
import ReadMoreLess from "../../components/ReadMoreLess";

const Solution = () => {
  const decisionTitleOne = `Faster processes,\nWell-informed decisions`;
  const decisionTitleTwo = `Meet your AI-powered\n co-pilot`;

  return (
    <div className="ourSolution-wrapper">
      <Navbar />
      <HeaderLand style={{ marginBottom: "60px" }} {...ourSolutionHeaderData} />
      <ScrollToTopButton />
      <div className="ourSolutions-desicion-wrapper">
        <div className="ourSolutions-desicion-container-one">
          <div className="ourSolutions-desicion-content">
            <h2 style={{ whiteSpace: "pre-wrap" }}>{decisionTitleTwo}</h2>
            <ReadMoreLess maxCharacterCount={250}>
              Stop wasting time learning new applications! We know that
              maintaining a clear overlook of all of your financial processes
              can seem nearly impossible. We are here to change that, all while
              causing no disruptions to your operations. Our system will run in
              parallel to yours, and our AI-powered co-pilot will answer your
              questions in seconds, gathering reports, delivering what-if
              analyses, and more! Tell us what to do and we will do it with the
              most natural form of interactions: speech and conversation
            </ReadMoreLess>
          </div>
          <div className="ourSolutions-desicion-img">
            <img src="/solutions/ai_power.png" alt="our decisions"></img>
          </div>
        </div>
        <div className="ourSolutions-desicion-container">
          <div className="ourSolutions-desicion-img">
            <img src="/solutions/decision.png" alt="our decisions"></img>
          </div>
          <div className="ourSolutions-desicion-content">
            <h2 style={{ whiteSpace: "pre-wrap" }}>{decisionTitleOne}</h2>
            <ReadMoreLess maxCharacterCount={250}>
              Your days are too short to tackle it all. Your team wastes far too
              many hours handling manual processes, putting together customized
              reports, avoiding regulatory compliance bottlenecks, and managing
              your siloed data sitting in far too many systems to keep track of.
              CashZar's all-inclusive solution was born to free up your time and
              resources, so you can make data-informed decisions, revert your
              focus to your core activities, and drive your growth
            </ReadMoreLess>
          </div>
        </div>
      </div>
      <div className="future-apps-wrapper">
        <h3>Future enhancements</h3>
        <div className="future-apps-container">
          {services.map((service) => (
            <ServiceCard {...service} />
          ))}
        </div>
      </div>
      <FAQList {...faqs} />
      <MaxEfficenty
        style={{ borderLeft: "10px solid #D3AF37" }}
        buttonColor="#E0871A"
        buttonTextColor="white"
        background="white"
      />
      <Footer />
    </div>
  );
};

export default Solution;
