import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { CEO } from "../../components/BlogContents/CEO";
import CoreValue from "../../components/BlogContents/CoreValue";
import "../../styles/blog-post.css";
import { blogPostCards } from "../../assets/app-data";
import CultureCard from "../../components/CultureCard/index";
import SignUpNews from "../../components/Signup";
import Automation from "../../components/BlogContents/Automation";
import FinancialNews from "../../components/BlogContents/FinancialNews";
import BusinessStrategies from "../../components/BlogContents/BusinessStrategies";
import FinancialStrategies from "../../components/BlogContents/FinancialStrategies";
import FutureTech from "../../components/BlogContents/FutureTech";
import Manufacturing from "../../components/BlogContents/Manufacturing";
import StartupAdvice from "../../components/BlogContents/StartupAdvice";
import TaxTips from "../../components/BlogContents/TaxTips";
import FinancialLiteracy from '../../components/BlogContents/FinancialLiteracy';

const Blogs = () => {
  const { contentId } = useParams();

  const getContent = () => {
    switch (contentId) {
      case "core-value":
        return <CoreValue />;
      case "ceo-letter":
        return <CEO />;
      case "automation":
        return <Automation />;
      case "business-strategies":
        return <BusinessStrategies />;
      case "financial-news":
        return <FinancialNews />;
      case "financial-strategies":
        return <FinancialStrategies />;
      case "future-tech":
        return <FutureTech />;
      case "manufacturing":
        return <Manufacturing />;
      case "startup-advice":
        return <StartupAdvice />;
      case "tax-tips":
        return <TaxTips />;
        case "financial-literacy":
        return <FinancialLiteracy/>;
      default:
        return <CEO />;
    }
  };

  return (
    <div className="blog-page-wrapper">
      <Navbar />
      <div className="blog-content">{getContent()}</div>
      <div className="blog-posts-container">
        {blogPostCards.map((card) => (
          <CultureCard key={card.id} {...card} />
        ))}
      </div>
      <SignUpNews />
      <Footer />
    </div>
  );
};

export default Blogs;
