import React from 'react';
import styles from '../../styles/Home.module.css'; // Make sure you import your styles correctly

interface StepsProps {
    id: number;
  step: string;
  description: string;
}

const Steps: React.FC<StepsProps> = ({ id, step, description }) => {
  return (
    <div key={id} className={styles.stepWrapper}>
      <h4>{step}</h4>
      <p>{description}</p>
    </div>
  );
};

export default Steps;