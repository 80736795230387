import "../../styles/terms.css";
import { address } from '../../assets/app-data';
import { Link } from "react-router-dom";

const PrivacyPolicyContent = () => {
  return (
    <div className="terms-wrapper">
      <h3>Privacy Policy for CashZar Inc</h3>
      <h4>Effective Date: January 1, 2024</h4>
      <h4>Introduction</h4>
      <p>
        Welcome to CashZar Inc. Your privacy is critically important to us.
        CashZar Inc is committed to respecting your privacy and protecting your
        personal information. This Privacy Policy explains our practices
        regarding the collection, use, and disclosure of your information
        through the use of our website{" "}
        <Link to="/policies/privacy-policy">
          Privacy Policy
        </Link>{" "}
        (the "Service"). By accessing or using the Service, you signify that you
        have read, understood, and agree to our collection, storage, use, and
        disclosure of your personal information as described in this Privacy
        Policy and our Terms of Service.{" "}
      </p>

      <h4>Information Collection and Use</h4>
      <p>
        We collect information that you provide directly to us, information
        automatically collected when you use our Services, and information from
        third-party sources. This may include, but is not limited to:
      </p>
      <ul>
        <li>
          Contact information, such as your name and email address, when you
          register.
        </li>
        <li>
          Transactional data, depending on the actions you take on our site.
        </li>
        <li>
          Technical data, such as cookies and usage details, to improve and
          analyze our Service.
        </li>
      </ul>
      <p>
        The exact information we collect will depend on the services you use and
        how you interact with CashZar Inc.
      </p>
      <h4>Use of Your Information</h4>
      <p>
        Our primary goal in collecting information is to provide and improve our
        Service, to administer your use of the Service, and to enable you to
        enjoy and easily navigate our Service. Specifically, we use your
        information to:
      </p>
      <ul>
        <li>
          Communicate with you about our services and provide customer support.
        </li>
        <li>Enhance the security and safety of our services.</li>
        <li>Conduct analytics and perform research.</li>
      </ul>
      <h4>Sharing of Your Information</h4>
      <p>
        We only share your information with third parties in the following
        circumstances:
      </p>
      <ul>
        <li>With your consent or at your direction.</li>
        <li>
          With service providers who work on our behalf and have agreed to
          adhere to the rules set forth in this Privacy Policy.
        </li>
        <li>
          To comply with laws or to respond to lawful requests and legal
          processes.
        </li>
      </ul>
      <h4>Your Rights and Choices</h4>
      <p>
        You have certain rights regarding the personal information we hold about
        you. These may include the right to access, modify, or delete the
        information we hold about you.
      </p>
      <h4>Changes to Our Privacy Policy</h4>
      <p>
        We may modify this Privacy Policy from time to time. The most current
        version of the policy will govern our processing of your personal data
        and will always be available on our website.
      </p>
      <h4>Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy or our practices,
        please contact us at:
      </p>
      <p>
        Email: <a>legal@cashzar.com</a>
      </p>
      <p>Address: {address}</p>
    </div>
  );
};

export default PrivacyPolicyContent;
