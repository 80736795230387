import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/headerLand.css";
import { HeaderLandProps } from "../types";

const HeaderLand: React.FC<HeaderLandProps> = ({
  title,
  description,
  img,
  component,
  style,
  videoSrc
}) => {
  const [maxWidth, setMaxWidth] = useState("");
  const location = useLocation(); // This will give you the current path

  const componentStyle: any = {
    marginBottom: img || videoSrc ? "25px" : "0px"
  }
  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      let newMaxWidth = '';

      if (windowWidth > 900) {
        if (location.pathname === "/") {
          newMaxWidth = "939px";
        } else if (location.pathname === "/about") {
          newMaxWidth = "740px";
        } else if (location.pathname === "/solutions") {
          newMaxWidth = "845px";
        }
      } else if (windowWidth > 680 && windowWidth <= 900) {
        newMaxWidth = "740px";
      } else {
        newMaxWidth = "80%";
      }

      setMaxWidth(newMaxWidth);
    }

    // Call the resize function
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]); // Re-run when the path changes
  return (
    <div style={style} className="homeHeaderWrapper">
      <div className="homeHeaderContainer">
        <div style={{ maxWidth: maxWidth }} className="homeHeader">
          <h1>{title}</h1>
          <div style={{ display: "flex", justifyContent: "center" }}><p>{description}</p></div>
        </div>
        {component && <div className="headerLand-component" style={componentStyle}>{component}</div>}
        {img && <img src={img} alt="Header Land"></img>}
        {videoSrc && (
          // <video width="100%" height="auto" controls>
          //   <source src={videoSrc} type="video/mp4" />
          //   Your browser does not support the video tag.
          // </video>
         <div >
          <iframe   width="1280" height="720px"  src={videoSrc} title="CashZar Product Explainer" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        )}
      </div>
    </div>
  );
};

export default HeaderLand;
