import Card from "./Card";
import article from "../../assets/articles.json";
import "../../styles/cardList.css";
import NavButton from "../Buttons/NavButton";
import { useState } from "react";

const CardList = () => {
  // State to keep track of the selected category
  const [selectedCategory, setSelectedCategory] = useState("View all");

  const style = {
    backgroundColor: "transparent",
    border: "none",
  };

  const categories = [
    "View all",
    "Business",
    "FinOps",
    "SME",
    "Technology",
  ];

  const handleCategoryChange = (category: any) => {
    setSelectedCategory(category);
  };

  const filteredArticles =
    selectedCategory === "View all"
      ? article
      : article.filter((item) => item.category === selectedCategory);

  return (
    <div className="cardList-wrapper">
      <div className="cardList-header">
        <h1>Stay on top of your game</h1>
        <p>
          Stay up to date with our articles on back office and finance
          management best practices, and discover all the benefits of our new
          product updates
        </p>
      </div>
      <div className="cardList-button-wrapper">
        {categories.map((category) => (
          <NavButton
            key={category}
            text={category}
            isFooter={false}
            isSelected={selectedCategory === category}
            onClick={() => handleCategoryChange(category)}
          />
        ))}
      </div>
      {filteredArticles.length > 1 ? (
        <div className="cardList-cards-container">
        {filteredArticles.map((item, index) => (
          <div className="cardlist-item">
            <Card
              key={item.id}
              {...item}
            />
          </div>
        ))}
        </div>
      ) : (
        <div className="single-card-container">
          {filteredArticles.map((item) => (
          <Card
            key={item.id}
            {...item}
          />
        ))}
        </div>
      )}
    </div>
  );
};

export default CardList;
