import { CultureCardProps } from "../components/types";
import {
  BenefitRowProps,
  HeaderLandProps,
  HighlightProps,
  MenuItem,
  StepData,
} from "../components/types";
import NewsLetter from "../components/Form/NewsLetterForm";
import { SVGs } from "./app-svg";
import NewsLetterForm from "../components/Form/NewsLetterForm";

// Address and Emails
export const address: string =
  "251 Little Falls Drive, Wilmington, New Castle County, Delaware 19808";
export const policyEmail: string = "legal@cashzar.com";
export const socialMedia = {
  youTube: "https://www.youtube.com/@CashZar",
  linkedin: "https://www.linkedin.com/company/cashzar",
  tweeter: "https://www.twitter.com/CashZarInc",
  instagram: "https://www.instagram.com/cashzarinc/",
  threads: "https://www.threads.net/@cashzarinc",
};

export const Services: MenuItem[] = [
  {
    name: "Trade Credit",
    route: "/service/trade-credit",
  },
  {
    name: "Treasury Management",
    route: "/service/treasury-management",
  },
  {
    name: "Tax Management",
    route: "/service/treasury-management",
  },
  {
    name: "Employee Management",
    route: "/service/treasury-management",
  },
  {
    name: "Bookkeeping",
    route: "/service/treasury-management",
  },
  {
    name: "Accounting",
    route: "/service/treasury-management",
  },
  {
    name: "FP&A",
    route: "/service/treasury-management",
  },
  {
    name: "AP/AR",
    route: "/service/treasury-management",
  },
  {
    name: "Other Services",
    route: "/service/treasury-management",
  },
  {
    name: "All services",
    route: "/service",
  },
];
// **********  page headears **********

// Home page
export const homeHeaderData: HeaderLandProps = {
  title: "We've got your back when it comes to back office and finance needs",
  description: (
    <p>
      <span className="highlight">Consider us an extension of your team,</span>
      {
        "streamlining operations to let you excel in your area of expertise. Our smart back office and finance services free up your valuable time and resources, empowering you to focus on what you do best"
      }
    </p>
  ),
  component: <NewsLetterForm />,
  // img: "/features/headerLand.png",
  videoSrc: "https://www.youtube.com/embed/3qgYbyxuhQQ",
};

// About page
export const aboutPageHeaderData: HeaderLandProps = {
  img: "aboutImage/aboutHeader.png",
  title: "Get to know us",
  description: `We're looking forward to getting to know your business needs.
    In the meantime, here's a little bit about us and our way of working.
    Get to know more about our founder, our philosophy, and our company history below`,
  component: <NewsLetterForm />,
};

// Our Solution
export const ourSolutionHeaderData: HeaderLandProps = {
  title: "Unlock financial efficiency",
  description: `CashZar eliminates the need for training, lengthy implementations, and complex integrations.
  Our technology specializes in handling complex financial workflows, intelligently tailored to seamlessly integrate with your existing technology stack and processes, as effortlessly as having a conversation, all at an affordable price`,
  component: <NewsLetterForm />,
};

// ********* High lights data ***********

// Home and about page sign up feature data
export const signupFeature: HighlightProps = {
  src: "/features/letter.png",
  title: "Sign up and stay in the loop",
  description:
    "Get the latest back office and finance management best practices, as well as product updates, delivered straight to your inbox every fortnight",
  component: <NewsLetter />,
};

// Our Solutions FAQs highlight
export const questions: HighlightProps = {
  svg: SVGs.question_hand,
  title: "FAQs",
  description:
    "Find answers to common questions about the features, compatibility, and technical requirements",
};

// Home page steps data
export const stepsData: StepData[] = [
  {
    id: 1,
    step: "Step 1: Integration",
    description:
      "CashZar seamlessly integrates with your existing software, automating data entry and reconciliation",
  },
  {
    id: 2,
    step: "Step 2: Automation",
    description:
      "Our AI-powered service perfectly replicates the processes it learns from your team, ensuring accuracy and time savings",
  },
  {
    id: 3,
    step: "Step 3: Efficiency",
    description:
      "With CashZar streamlining your financial processes, you're ready to achieve peak efficiency",
  },
  {
    id: 4,
    step: "Step 4: Focus",
    description:
      "CashZar handles your day-to-day financial operations, and you can focus on your core activities",
  },
];

// About page benefits cards data
export const benefits: BenefitRowProps[] = [
  {
    isLeft: true,
    img: "/aboutImage/about1.png",
    isTwoPart: true,
    isHorizontal: true,
    title:" Our Vision & Mission",
    description:"",
    firstTitle: "Our Vision",
    secondTitle:" Our Mission",
    firstDescription: "To empower businesses of all sizes and stages to operate with maximum efficiency.",
    secondDescription: "To organize and digitize every process and workflow for businesses across the globe."
  },
  {
    isLeft: false,
    img: "/aboutImage/about2.png",
    isTwoPart: true,
    isHorizontal: false,
    title: "Our philosophy",
    description:
      "Our principles and values are our guiding lights. They help us show up for our clients and deliver excellence - consistently. Learn more about our way of working by diving deeper into our beliefs below",
    firstTitle: "Our principles",
    firstDescription:
      "Our principles are the fundamental truths guiding our decisions, actions, and culture",
    secondTitle: "Our values",
    secondDescription:
      "Our values shape our culture, drive our decision-making, and define who we are as a company",
  },
];

// About page cultures cards data
export const cultures: CultureCardProps[] = [
  {
    id: 1,
    img: "/aboutImage/ceo.png",
    title: "A letter from our CEO",
    description:
      "Read this letter from our CEO Bahador Biglari to learn about his path, and what propels our team forward",
    date: "11 Jan 2024",
    type: "Culture",
    link: "/blogs/ceo-letter/",
  },
  {
    id: 2,
    img: "/aboutImage/teams.png",
    title: "Our teams.",
    description:
      "Our principles are our guiding lights. Learn more about how they help us deliver excellence consistently",
    date: "11 Jan 2024",
    type: "Culture",
    link: "",
  },
  {
    id: 3,
    img: "/aboutImage/corevalues.png",
    title: "Our values",
    description:
      "Discover our values and how they shape our culture, drive our decisions, and define who we are",
    date: "11 Jan 2024",
    type: "Culture",
    link: "/blogs/core-value/"
  },
];

export const blogPostCards: CultureCardProps[] = [
  {
    "id": 1,
    "img": "/news/48.png",
    "title": "Maximizing Financial Performance",
    "description": "The Strategic Edge of Automation in Finance Operations",
    "type": "Category",
    "link": "/blogs/automation",
},
{
    "id": 2,
    "img": "/news/news4.png",
    "title": "Driving SMB Growth with Enterprise-Grade SaaS Solutions",
    "description": "Explore how SaaS solutions can accelerate SMB growth",
    "type": "Category",
    "link": "/blogs/manufacturing",
},
{
    "id": 3,
    "img": "/news/30.png",
    "title": "The Importance of Financial Literacy for SME Owners",
    "description": "Unveiling the critical role of financial literacy in empowering SME owners",
    "type": "Category",
    "link": "/blogs/financial-literacy",
}
];
