import { useEffect, useState } from "react";
import styles from "../../styles/Navbar.module.css";
import { SVGs } from "../../assets/app-svg";
import NavButton from "../Buttons/NavButton";
import { Link, useLocation } from "react-router-dom";
import BaseButton from "../Buttons/BaseButton";
import { FiMenu } from "react-icons/fi";
import JoinBetaPopup from "../Form/JoinBetaPopup";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const location = useLocation();
  const [showJoinBetaPopup, setJoinBetaPopup] = useState(false);

  const togglePopup = () => {
    setJoinBetaPopup(!showJoinBetaPopup);
  };

  const handleResize = () => {
    if (window.innerWidth > 780) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    setSelected(location.pathname);
    handleResize();
    // Scroll to the top of the page on location change
    window.scrollTo(0, 0);
    // Event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location, menuOpen]);

  console.log(selected);
  return (
    <div aria-label="Navbar" className={styles.navbarContainer}>
      <Link to="/">
        <div aria-label="CashZar logo" className={styles.navbarLogo}>
          {SVGs.cz_cashzar_logo}
        </div>
      </Link>
      <div
        className={styles.navMenu}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        style={{ fontSize: "40px" }}
      >
        <FiMenu />
      </div>
      <div
        className={menuOpen ? styles.navOpenMenu : styles.navbarButtonsWrapper}
      >
        <NavButton
          isFooter={false}
          text="Home"
          link="/"
          isSelected={selected === "/"}
        />

        <NavButton
          isFooter={false}
          text="Solutions"
          link="/solution"
          isSelected={selected === "/solution"}
        />
        <NavButton
          isFooter={false}
          text="Company"
          link="/company"
          isSelected={selected === "/company"}
        />
        <NavButton
          isFooter={false}
          text="Resources"
          link="/resources"
          isSelected={selected === "/resources"}
        />
      </div>
      <div className={styles.navDemoButton}>
        <BaseButton
          text="Join Beta"
          background="#E0871A"
          color="white"         
          onClick={togglePopup}
        />
      </div>
      <JoinBetaPopup show={showJoinBetaPopup} onClose={togglePopup} />
    </div>
  );
};

export default Navbar;
