import React from "react";
import { Link } from "react-router-dom";
import "../../styles/baseButton.css";

type BaseButtonProps = {
  text: string;
  background?: string;
  onClick?: () => void;
  link?: string;
  width?: string;
  color?: string;
};

const BaseButton: React.FC<BaseButtonProps> = ({
  text,
  background,
  onClick,
  link,
  width,
  color,
}) => {
  const backgroundColor = background ? background : "#D3AF37";

  const buttonStyle = {
    background: background ? backgroundColor : "transparent",
    color: color ? color : "black",
    border: background ? "none" : "1px solid black",
    width: width ? width : "fit-content",
  };
  const button = {
    background: background ? backgroundColor : "transparent",
    color: color ? color : "black",
    width: width ? width : "fit-content",
  };
  return (
    <div
      style={{
        background: background,
        textAlign: "center",
        border: buttonStyle.border,
      }}
      className="base-button-wrapper"
    >
      {link ? (
        <Link to={link} style={buttonStyle}>
          {text}
        </Link>
      ) : (
        <button onClick={onClick} style={button}>
          {text}
        </button>
      )}
    </div>
  );
};

export default BaseButton;
