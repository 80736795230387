import React, { useState } from "react";
import "../../styles/readMore.css";

interface Props {
  children: React.ReactNode;
  maxCharacterCount: number;
}
const ReadMoreLess: React.FC<Props> = ({
  children,
  maxCharacterCount = 100,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);

  // Check if children is a string and if it needs the Read More functionality
  const text = typeof children === "string" ? children : "";
  const needsReadMore = text.length > maxCharacterCount;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p>
      {needsReadMore ? (
        <>
          {isReadMore ? text.slice(0, maxCharacterCount) : text}
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? "... Read more" : " Read less"}
          </span>
        </>
      ) : (
        children
      )}
    </p>
  );
};

export default ReadMoreLess;
