import { CultureCardProps } from "../types";
import "../../styles/cultureCard.css";
import { SVGs } from "../../assets/app-svg";
import { Link } from "react-router-dom";

const CultureCard: React.FC<CultureCardProps> = (props) => {
  const {
    id,
    img,
    title,
    description,
    date = "11 Jan 2024",
    type,
    link,
  } = props;
  return (
    <div key={id} className="culture-card-wrapper">
      <Link to={link}>
        <div
          className="culture-card-img"
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="culture-card-content">
          <div>
          <div className="culture-card-content-top">
            <div className="culture-card-content-type">
              <p>{type}</p>
            </div>
          </div>
          <div className="culture-card-content-head">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          </div>
          
          <div className="culture-card-icon">
            <div>{SVGs.culture_book}</div>
            <p>
              {date}&nbsp;&nbsp;&nbsp;
              <a style={{ textDecoration: "underline" }}>Read More</a>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CultureCard;
