import React from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/NavButton.module.css";

interface NavButtonProps {
  text: string;
  isSelected?: boolean;
  link?: string;
  isFooter?: boolean; 
  onClick?: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({
  text,
  isSelected,
  link,
  isFooter,
  onClick,
}) => {
  const textStyle = {
    color: isFooter ? '#ffffff' : '#112347',
  };

  const selectedStyle = isSelected ? { borderBottom: '3px solid #E0871A' } : {};
  return (
    <div className={styles.buttonWrapper}>
      {link ? (
        <Link
          to={link}
          className={`${styles.navButtonWrapper} ${isSelected ? styles.selected : ''}`}
          style={{...textStyle, ...selectedStyle}}
        >
          {text}
        </Link>
      ) : (
        <button
          onClick={onClick}
          className={`${styles.navBtnWrapper} ${isSelected ? styles.selected : ''}`}
          style={{...textStyle, ...selectedStyle}}
        >
          {text}
        </button>
      )}
    </div>
  );
};

export default NavButton;
