import { Link } from "react-router-dom";
import { policyEmail } from "../../assets/app-data";
import "../../styles/terms.css";

const CookiePolicyContent = () => {
  return (
    <div className="terms-wrapper">
      <h3>Cookie Policy for CashZar Inc</h3>
      <h4>Effective Date: January 1, 2024</h4>
      <p>
        At CashZar Inc, accessible from <a href="http://cashzar.com">www.cashzar.com</a>, one of our main
        priorities is the privacy of our visitors. This Cookie Policy document
        contains types of information that are collected and recorded by CashZar
        Inc and how we use it. If you have additional questions or require more
        information about our Cookie Policy, do not hesitate to contact us
        through email at <a>legal@cashzar.com</a>.
      </p>
      <h4>What Are Cookies?</h4>
      <p>
        Cookies are small text files that are used to store small pieces of
        information. They are stored on your device when the website is loaded
        on your browser. These cookies help us make the website function
        properly, make it more secure, provide better user experience, and
        understand how the website performs and to analyze what works and where
        it needs improvement.
      </p>
      <h4>How We Use Cookies</h4>
      <p>
        As most of the online services, our website uses first-party and
        third-party cookies for several purposes. First-party cookies are mostly
        necessary for the website to function the right way, and they do not
        collect any of your personally identifiable data. The third-party
        cookies used on our websites are mainly for understanding how the
        website performs, how you interact with our website, keeping our
        services secure, providing advertisements that are relevant to you, and
        all in all providing you with a better and improved user experience and
        help speed up your future interactions with our website.
      </p>
      <h4>What Types Of Cookies Do We Use?</h4>
      <ul>
        <li>
          <h5>Essential:</h5>
          <p>
            essential for you to be able to experience the full functionality of
            our site. They allow us to maintain user sessions and prevent any
            security threats. They do not collect or store any personal
            information.
          </p>
        </li>
        <li>
          <h5>Statistics:</h5>
          <p>
            These cookies store information like the number of visitors to the
            website, the number of unique visitors, which pages of the website
            have been visited, the source of the visit, etc. This data helps us
            understand and analyze how well the website performs and where it
            needs improvement.
          </p>
        </li>
        <li>
          <h5>Marketing:</h5>
          <p>
            Our website displays advertisements. These cookies are used to
            personalize the advertisements that we show to you so that they are
            meaningful to you. These cookies also help us keep track of the
            efficiency of these ad campaigns.
          </p>
        </li>
        <li>
          <h5>Functional:</h5>
          <p>
            These are the cookies that help certain non-essential
            functionalities on our website. These functionalities include
            embedding content like videos or sharing content of the website on
            social media platforms.
          </p>
        </li>
        <li>
          <h5>Preferences:</h5>
          <p>
            These cookies help us store your settings and browsing preferences
            like language preferences so that you have a better and efficient
            experience on future visits to the website.
          </p>
        </li>
      </ul>
      <p>
        Your Choices Regarding Cookies If you decide at any time that you no
        longer wish to accept cookies from our site for any of the purposes
        described above, then you can usually modify your browser settings to
        decline cookies or to prompt you before accepting a cookie from the
        websites you visit. Please note that if you delete cookies or decline to
        accept them, you might not be able to use all of the features we offer,
        you may not be able to store your preferences, and some of our pages
        might not display properly.
      </p>
      <p>
        For more information on how to manage and delete cookies, visit
        internetcookies.org. Privacy Policy For more information about how we
        handle data, please visit our Privacy Policy at <Link to="/policies/privacy-policy">Privacy Policy</Link>. 
      </p>
      <p>Contact Us If you have any questions about
        this Cookie Policy, please contact us: By email: <a>{policyEmail}</a></p>
    </div>
  );
};

export default CookiePolicyContent;
