import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/ServiceCard.module.css";

interface ServiceCardProps {
  id: number;
  title: string;
  description: string;
  img: string;
  link?: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = (props) => {
  const { id, title, description, img, link } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to truncate the description to a certain length
  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div key={id} className={styles.serviceCardWrapper}>
      <div className={styles.serviceCardImg}>
        <img src={img} alt={title} />
      </div>
      <div className={styles.serviceCardContent}>
        <h4>{title}</h4>
        <p>
          {isExpanded ? description : truncate(description, 55)}
          <span className={styles.more} onClick={toggleDescription}>
            {isExpanded ? ' less' : ' more'}
          </span>
        </p>
      </div>
      {link && <Link to={link}>Learn more</Link>}
    </div>
  );
};
