import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slidesData from "../../assets/slides.json";
import "../../styles/FeatureSlider.css";
import { ServiceCard } from "../ServiceCard";

const FutureApps: React.FC = () => {

  return (
    <div className="slider-main-container">
      <div className="slider-header-container">
        <div className="slider-header-top">
          <h3>Our features</h3>
        </div>
        <div className="slider-header-second-wrapper">
          <div className="slider-header-second-one">
            <h3>Smart features. Boundless improvements.</h3>
          </div>
          <div className="slider-header-second-two">
            <p>
              Explore some of our main features below and discover how they'd
              enable your business to reach your goals.
            </p>
          </div>
        </div>
      </div>
      <div className="temp-future-apps">
        {slidesData.map((slide) => (
          <ServiceCard
          id={slide.id} title={slide.title} description={slide.description} img={slide.image}
          />
        ))}
      </div>
    </div>
  );
};

export default FutureApps;
