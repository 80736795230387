import { SVGs } from "../../assets/app-svg";
import { BenefitRowProps } from "../types";
import "../../styles/benefitRow.css";
import BaseButton from "../Buttons/BaseButton";
import { useState } from "react";

const BenefitRow: React.FC<BenefitRowProps> = (props) => {
  const {
    isLeft,
    img,
    isTwoPart,
    isHorizontal,
    title,
    details,
    description,
    firstTitle,
    firstDescription,
    secondTitle,
    secondDescription,
  } = props;
  

  return (
    <div className={`${isLeft ? "leftImgRow" : "rightImgRow"} rowWrapper`}>
      <div
        className="row-img"
      > <img src={img} alt="title"></img></div>
      {isTwoPart && isHorizontal ? (
        <div className="right-row-content-wrapper-horizontal">
          <div className="right-row-content-first">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className="right-row-content-second-horizontal ">
            <div>
            <div className="content-with-image-and-title">
              <div> {SVGs.Hexagon} </div>
              <h4>{firstTitle}</h4>
              </div>
              <p>{firstDescription}</p>
            </div>
            <div>
            <div className="content-with-image-and-title">
              <div> {SVGs.Hexagon} </div>
              <h4>{secondTitle}</h4>
              </div>
              <p>{secondDescription}</p>
            </div>
          </div>
        </div>
      ): isTwoPart ? (
        <div className="right-row-content-wrapper">
          <div className="right-row-content-first">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className="right-row-content-second">
            <div>
              <div className="content-with-image-and-title">
              <div> {SVGs.Hexagon} </div>
              <h4>{firstTitle}</h4>
              </div>
              <p>{firstDescription}</p>
            </div>
            <div>
            <div className="content-with-image-and-title">
              <div> {SVGs.Hexagon} </div>
              <h4>{secondTitle}</h4>
              </div>
              <p>{secondDescription}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="left-row-content-wrapper">
          <div className="left-row-content">
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <div className="vector-list">
            {props.details? props.details.map((item, index) => (
              <div className="vector-list-item" key={index}>
                <div id="tiny-vector">{SVGs.tinyVector}</div>
                <p>{item}</p>
              </div>
            )) : ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BenefitRow;
