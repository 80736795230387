import { useState, useEffect } from "react";
import styles from "../../styles/ScrollToTop.module.css";
import { SVGs } from "../../assets/app-svg";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      // Checking if the page is scrolled more than 400px
      setIsVisible(window.scrollY > 400);
    };

    window.addEventListener("scroll", checkScrollTop);

    // Removing event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Only render the button if `isVisible` is true
  if (!isVisible) return null;

  return (
    <button
      className={`${styles.scrollToTopButton} ${isVisible ? styles.visible : ""}`}
      onClick={scrollToTop}
      aria-label="Scroll to top"
    >
      <div className={styles.scrollToTopSVG}>
        {SVGs.scroll_top}
        
      </div>
    </button>
  );
};
