import BlogHeader from "../BlogHeader";
import "../../styles/blogContent.css";
import secondImage from "../../images/blogs/3.png";
import firstImage from "../../images/blogs/4.png";

const CoreValue = () => {
  const coreValueContent = {
    title: "Our Values, Principles, & Behaviors",
    img: firstImage,
    startPath: "Company",
    endPath: "Core Value",
    link: "/company/"
  };
  return (
    <div>
      <BlogHeader {...coreValueContent} />
      <div className="shared-blog-content-wrapper">
        <div className="shared-blog-content-container">
          <h3>Note from CEO </h3>
          <p>
            Welcome to CashZar. As the CEO, I am thrilled to introduce you to
            our company, our values, and our vision. But before we delve into
            that, I want to share a bit of my personal journey with you.
          </p>
          <br></br>

          <p>
            During my undergraduate studies in Canada, I faced some of the most
            challenging times of my life. I was homeless for several months, and
            there were days when I didn't have enough money to eat. On the best
            of those days, I could afford a serving of french fries every other
            night.
          </p>
          <br></br>
          <div className="blog-post-img-two">
            <img src={secondImage} alt="Welcome to CashZar"></img>
          </div>
          <br></br>
          <p>
            These experiences shaped me and, in turn, shaped the values we hold
            at CashZar. I learned the importance of empathy, kindness, and
            non-judgment. I experienced firsthand the impact that each of us can
            have on the lives of others, for better or worse. I understood the
            power of community and the strength that comes from supporting each
            other. These lessons are at the heart of our values at CashZar.
          </p>
          <br></br>
          <p>
            So, as you read this booklet, I want you to remember the impact that
            we all have on others' lives. It matters. It can make or break
            others. We are all leaders in some form or shape. Someone, somewhere
            in this world, is following us, looking up to us. So, the question I
            want you to ask yourself is, "What would I like to be remembered
            for?" At CashZar, we are not just a team we're a community. We're
            here for each other, through the good times and the bad, growing
            together to redefine the world of business operation. I am proud of
            what we have built so far, and I am excited about what we will
            achieve together in the future.
          </p>
          <br></br>
          <p>
            Thank you for being a part of this journey. Together, we can achieve
            great things. 
          </p>
          <p>To see our full values and principles click the link: <a style={{color:"blue"}} href="https://docs.google.com/document/d/1UHzXbRMg4f18PDQh7I4VOu0G_aBGPGIzXf4jdLrNpNY/edit#heading=h.ll5mkofd78r7">Values and Principles</a></p>
          <br></br>
          <p>
            Best,<br></br>
            Bahador Biglari<br></br>
            CEO, CashZar
          </p>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default CoreValue;
