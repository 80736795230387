import { SVGs } from "../../assets/app-svg";
import NewsLetterForm from "../Form/NewsLetterForm";
import "../../styles/signupForm.css";

const SignUpNews = () => {
  return (
    <div className="signup-feature-wrapper">
      <div className="signup-feature-container">
        <div className="signup-content-header">
          <h3>Sign up and stay in the loop</h3>
        </div>
        <div className="signup-content-container">
          <div className="signup-content-icon">{SVGs.thumbs_up}</div>
          <div className="signup-content-bar">
            <p>
              Get the latest back office and finance management best practices,
              as well as product updates, delivered straight to your inbox every
              fortnight
            </p>
            <NewsLetterForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpNews;
