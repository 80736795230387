import "../../styles/terms.css";
import { address, policyEmail } from '../../assets/app-data';

const TermsAndConditionsContent = () => {
  return (
    <div className="terms-wrapper">
      <h3>Terms and Conditions for CashZar Inc</h3>
      <h4>Effective Date: January 1, 2024</h4>
      <p>
        Welcome to CashZar Inc! These Terms and Conditions ("Terms") govern your
        use of our website and all related services, products, platforms, and
        resources offered by CashZar Inc, a Delaware company ("we", "us", or
        "our"), collectively referred to as the "Service". By accessing or using
        the Service, you agree to be bound by these Terms. If you disagree with
        any part of the terms, then you may not access the Service.
      </p>
      <div>
        <ol>
          <li>Acceptance of Terms</li>
          <p>
            By using our Service, you confirm that you have read, understood,
            and agreed to these Terms. If you are using our Service on behalf of
            an organization or entity, you are agreeing to these Terms for that
            organization and confirming that you have the authority to bind that
            organization to these Terms.
          </p>
          <li>Description of Service</li>
          <p>
            CashZar Inc provides financial management services to companies of
            any size to facilitate better decision-making. Our comprehensive
            suite of services is designed to help businesses streamline their
            financial operations, enhance efficiency, and improve their overall
            financial health.
          </p>
          <li>User Accounts</li>
          <p>
            To access certain features of the Service, you may be required to
            create an account. You are responsible for maintaining the
            confidentiality of your account and password and for restricting
            access to your computer.
          </p>
          <li>Privacy Policy</li>
          <p>
            Your use of the Service is also governed by our Privacy Policy,
            which is incorporated herein by reference.
          </p>
          <li>Intellectual Property</li>
          <p>
            The Service and its original content, features, and functionality
            are and will remain the exclusive property of CashZar Inc and its
            licensors.
          </p>
          <li>Use Restrictions</li>
          <p>You agree not to use the Service in any way that:</p>
          <ul>
            <li>
              Violates any applicable national, regional, federal, state, local,
              or international law or regulation.
            </li>
            <li>
              Infringes on the rights of others, including but not limited to
              intellectual property rights.
            </li>
            <li>
              Is harmful, fraudulent, deceptive, threatening, harassing,
              defamatory, obscene, or otherwise objectionable.
            </li>
          </ul>
          <li>Termination</li>
          <p>
            We may terminate or suspend your access to our Service immediately,
            without prior notice or liability, for any reason whatsoever,
            including, without limitation, if you breach the Terms.
          </p>
          <li>Limitation of Liability</li>
          <p>
            In no event will CashZar Inc, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from your access to or use of
            or inability to access or use the Service.
          </p>
          <li>Governing Law</li>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the State of Delaware, United States, without regard to its
            conflict of law provisions.
          </p>
          <li>Changes</li>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. We will provide notice of any changes by
            posting the new Terms on our website.
          </p>
          <li>Contact Us</li>
          <p>
            If you have any questions about these Terms, please contact us at:
          </p>
          <p>
            Email: <a>{policyEmail}</a>
          </p>
          <p>Address: {address}</p>
        </ol>
      </div>
    </div>
  );
};

export default TermsAndConditionsContent;
