import NavButton from "../../components/Buttons/NavButton";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import styles from "../../styles/Newsletter.module.css";
import blog from "../../assets/blogs.json";
import CultureCard from "../../components/CultureCard";
import SignUpNews from "../../components/Signup";

import { SVGs } from "../../assets/app-svg";
import { useState } from "react";
import BaseButton from "../../components/Buttons/BaseButton";
import FeedbackPopup from "../../components/FeedbackPopup";

const Resources = () => {
  const [selectedCategory, setSelectedCategory] = useState("View all");
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  const togglePopup = () => {
    setShowFeedbackPopup(!showFeedbackPopup);
  };
  const categories = [
    "View all",
    "Business",
    "FinOps",
    "SME",
    "Technology",
  ];
  
  const handleCategoryChange = (category: any) => {
    setSelectedCategory(category);
  };

  const filteredArticles =
    selectedCategory === "View all"
      ? blog
      : blog.filter((item) => item.category === selectedCategory);

  return (
    <div>
      <Navbar />
      <div className={styles.newsletterHeaderWrapper}>
        <div className={styles.newsletterMainHeader}>
          <h6>Insights</h6>
          <h2>Stay on top of your game</h2>
          <p>
            Stay up to date with our articles on back office and finance
            management best practices, and discover all the benefits of our new
            product updates
          </p>
        </div>

        <div className={styles.newsletterButtonsWrapper}>
        {categories.map((category) => (
          <NavButton
            key={category}
            text={category}
            isFooter={false}
            isSelected={selectedCategory === category}
            onClick={() => handleCategoryChange(category)}
          />
        ))}
          
        </div>
        <div className={styles.newsletterCardsWrapper}>
          {filteredArticles.map((card) => (
            <CultureCard key={card.id} {...card} />
          ))}
        </div>
      </div>
      <div className={styles.getQuestionsAnswersWrapper}>
        <div className={styles.getQuestionsAnswersContainer}>
          <div className={styles.getQuestionsAnswersSignup}>
            <h3>Get your questions answered</h3>
            <p>Share your topic ideas with us and start a conversation</p>
            <div className={styles.getQuestionsAnswersPopup}><BaseButton text="Ask us question" color="white" background="#1C2D5A"  onClick={togglePopup}/></div>
          </div>
          <FeedbackPopup show={showFeedbackPopup} onClose={togglePopup} />
          <div className={styles.getQuestionsAnswersIcon}>{SVGs.open_hand_with_question}</div>
        </div>
      </div>
      <SignUpNews />
      <Footer />
    </div>
  );
};

export default Resources;
