import "../../styles/blog-post.css";
import { SVGs } from "../../assets/app-svg";
import { Link } from "react-router-dom";
import { socialMedia } from "../../assets/app-data";
import { useEffect, useState } from "react";
import contactBackground from "../../images/ceo1.png";
import contactBackgroundMobile from "../../images/bahador.jpeg";
import { IoIosArrowForward } from "react-icons/io";

export const CEO = () => {
  const [backgroundImage, setBackgroundImage] = useState(contactBackground);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 550) {
        setBackgroundImage(contactBackgroundMobile);
      } else {
        setBackgroundImage(contactBackground);
      }
    };

    // Set the background image based on the initial window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="blog-post-wrapper">
      <div className="blog-post-first-section">
        <div className="blog-header-path">
          <Link to="/ceo-letter/">
            <h6
              style={{
                color: "#E0871A",
                textDecorationLine: "underline",
                marginRight: "8px",
              }}
            >
              Company
            </h6>
          </Link>
          <IoIosArrowForward />
          <h6 style={{ color: "#D3AF37", marginLeft: "8px" }}>
            The letter from CEO
          </h6>
        </div>

        <div className="blog-post-title">
          <h2>A letter from our CEO</h2>
        </div>
        <div className="blog-subtitle-container">
          <div className="blog-date-time">
            <div className="blog-date-icon">{SVGs.culture_book}</div>
            <p
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              11 Jan 2024
            </p>
            <p
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {" "}
              . 5 min read
            </p>
          </div>
          <div className="blog-social-icon-wrapper">
            <Link to="" target="_blank" className="blog-social-icon">
              {SVGs.linkIcon}
            </Link>
            <Link
              to={socialMedia.linkedin}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.linkedin}
            </Link>
            <Link
              to={socialMedia.youTube}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.youtube}
            </Link>
            <Link
              to={socialMedia.instagram}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.instagram_green}
            </Link>
            <Link
              to={socialMedia.threads}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.thread}
            </Link>
          </div>
        </div>
        {/* This shows only in media smaller than 550px */}
        <div className="blog-subtitle-small_media">
          <div className="blog-date-time">
            <div className="blog-date-icon">{SVGs.small_book}</div>
            <div className="blog-date-text">
              <p>11 Jan 2024</p>
              <p>5 min read</p>
            </div>
          </div>
          <div className="blog-social-icon-wrapper">
            <Link to="" target="_blank">
              {SVGs.link_small}
            </Link>
            <Link
              to={socialMedia.linkedin}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.small_linkedin}
            </Link>
            <Link
              to={socialMedia.youTube}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.small_youtube}
            </Link>
            <Link
              to={socialMedia.instagram}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.small_insta}
            </Link>
            <Link
              to={socialMedia.threads}
              target="_blank"
              className="blog-social-icon"
            >
              {SVGs.small_thread}
            </Link>
          </div>
        </div>
        <div
          className="image-stack"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",

            backgroundRepeat: "no-repeat",
          }}
        >
          <img
            src="/blogs/ceo.png"
            alt="CEO Front"
            className="stacked-image-front"
          />
        </div>
      </div>
      <div className="blog-post-second-section">
        <div className="blog-post-content-container">
          <h3>Welcome to CashZar</h3>
          <p>
            As the CEO, I am thrilled to introduce you to our company, our
            values, and our vision. But before we delve into that, I want to
            share a bit of my personal journey with you.
          </p>
          <p>
            During my undergraduate studies in Canada, I faced some of the most
            challenging times of my life. I was homeless for several months, and
            there were days when I didn't have enough money to eat. On the best
            of those days, I could afford a serving of french fries every other
            night.
          </p>
          <div className="blog-post-second-img">
            <img src="/blogs/ceo2.png" alt="Welcome to CashZar"></img>
          </div>
          <p>
            These experiences shaped me and, in turn, shaped the values we hold
            at CashZar. I learned the importance of empathy, kindness, and
            non-judgment. I experienced firsthand the impact that each of us can
            have on the lives of others, for better or worse. I understood the
            power of community and the strength that comes from supporting each
            other. These lessons are at the heart of our values at CashZar.
          </p>
          <div className="blog-post-content-quotes">
            <p>
              "I experienced firsthand the impact that each of us can have on
              the lives of others, for better or worse."
            </p>
          </div>
          <p>
            So, as you read this booklet, I want you to remember the impact that
            we all have on others' lives. It matters. It can make or break
            others. We are all leaders in some form or shape. Someone, somewhere
            in this world, is following us, looking up to us. So, the question I
            want you to ask yourself is, "What would I like to be remembered
            for?"
          </p>
          <p>
            At CashZar, we are not just a team – we're a community. We're here
            for each other, through the good times and the bad, growing together
            to redefine the world of fintech. I am proud of what we have built
            so far, and I am excited about what we will achieve together in the
            future.
          </p>
          <p>
            Thank you for being a part of this journey. Together, we can achieve
            great things.
          </p>
          <p>Best,</p>
          <p>
            Bahador Biglari<br></br>CEO, CashZar
          </p>
        </div>
      </div>
    </div>
  );
};
