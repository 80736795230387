import FAQList from "../../components/FAQ";
import Footer from "../../components/Footer";
import NewsLetterForm from "../../components/Form/NewsLetterForm";
import GetInTouch from "../../components/GetInTouch";
import MaxEfficenty from "../../components/MaxEfficenty";
import Navbar from "../../components/Navbar";
import { ScrollToTopButton } from "../../components/ScrollToTop";
import "../../styles/join-beta.css";

const JoinBeta = () => {
  const twoPartTitle = `Experience the Power of AI\n in Financial Management`;
  return (
    <div>
      <Navbar />
      <div className="join-beta-top-header">
        <div className="join-beta-top-header-first">
          <div className="join-beta-top-header-img-wrapper">
            <h2>Join our beta today</h2>
            <div id="join-beta-img-1">
              <img src="/join-beta/headerHands.png"></img>
            </div>
          </div>
          <div className="join-beta-top-header-signup">
            <p>
              Be part of our exclusive beta program and experience the power of
              CashZar's AI-driven back office services. What are you waiting
              for?
            </p>
            <NewsLetterForm />
            <img src="/join-beta/headerHands.png" id="join-beta-img"></img>
            <div id="join-beta-img-2">
              <img src="/join-beta/headerHands.png"></img>
            </div>
          </div>
        </div>
        <div className="join-beta-top-header-second">
          <div className="Percentage-header">
            <h2>{twoPartTitle}</h2>
            <p>
              CashZar's AI-powered back office services automate tasks, ensure
              accuracy, and free up businesses to focus on their core
              activities. Joining our beta program gives you early access plus
              the opportunity to influence our product design before launch
            </p>
          </div>
          <div className="Percentage-wrapper">
            <div className="Percentage-item">
              <h2 style={{ color: "#E0871A" }}>40%</h2>
              <p>IT cost reductions</p>
            </div>
            <div className="Percentage-item">
              <h2 style={{ color: "#4B5DAA" }}>90%</h2>
              <p>Less customization</p>
            </div>
            <div className="Percentage-item">
              <h2 style={{ color: "#C63E96" }}>3x</h2>
              <p>User adoption</p>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <GetInTouch />
      <FAQList />
      <MaxEfficenty
        style={{ borderLeft: "10px solid #D3AF37" }}
        buttonColor="#E0871A"
        buttonTextColor="white"
        background="white"
      />
      <Footer />
    </div>
  );
};

export default JoinBeta;
