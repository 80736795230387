import React, { FormEvent } from "react";
import "../../styles/joinBetaForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";

const JoinBetaForm = () => {
  const revenue = [
    "< $1 million",
    "1 <> $10 million",
    "10 <> $50 million",
    "$50 million <",
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // This line prevents the default form action
    const form = event.currentTarget;
    const formData = new FormData(event.currentTarget);
    const formDataObj = Object.fromEntries(formData);

    const formBody = new URLSearchParams(formDataObj as any).toString();

    try {
      const response = await fetch(
        "https://docs.google.com/forms/u/0/d/e/1FAIpQLSc5q8Uc4bd_BMIJLtVDR6uUnw_VEyjiNesnJcx3RhhZZUIG-A/formResponse",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );

      toast.success("Success! Thank you for joining our beta.", {
        onClose: () => {
          // Using navigate to refresh the current page by navigating to the current path
          navigate(location.pathname, { replace: true });
        },
      });
      // clear the form
      form.reset();
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Submission failed. Please try again.");
    }
  };

  return (
    <div className="form-wrapper">
      <div className="form-container">
        <div className="form-header">
          <h2>Join Beta</h2>
        </div>
        <form
          onSubmit={handleSubmit}
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc5q8Uc4bd_BMIJLtVDR6uUnw_VEyjiNesnJcx3RhhZZUIG-A/formResponse"
          method="POST"
          className="join-beta-form"
        >
          <div className="form-group">
            <div className="form-fullname">
              <div className="form-first-name">
                <label htmlFor="entry.2072302840">First Name</label>
                <input
                  type="text"
                  name="entry.2072302840"
                  id="entry.2072302840"
                />
              </div>

              <div className="form-last-name">
                <label htmlFor="entry.1977558870">Last Name</label>
                <input
                  type="text"
                  name="entry.1977558870"
                  id="entry.1977558870"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="entry.2028086398">Business Email</label>
            <input type="text" name="entry.2028086398" id="entry.2028086398" />
          </div>

          <div className="form-group">
            <label htmlFor="entry.1536842890">Website</label>
            <input type="text" name="entry.1536842890" id="entry.1536842890" />
          </div>

          <div className="form-group">
            <label htmlFor="entry.654421730">Your Role</label>
            <select name="entry.654421730" id="entry.654421730">
              <option>Select</option>
              <option value="CEO">CEO</option>
              <option value="CFO">CFO</option>
              <option value="Controller">Controller</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="entry.849105797">Region</label>
            <select name="entry.849105797" id="entry.849105797">
              <option>Select</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
              <option value="Europe">Europe</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="entry.167563753">Accounting Solution</label>
            <select name="entry.167563753" id="entry.167563753">
              <option>Select</option>
              <option value="Odoo">Odoo</option>
              <option value="QuickBooks">QuickBooks</option>
              <option value="Sage">Sage</option>
              <option value="Xero">Xero</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="entry.1608106190">Industry</label>
            <select name="entry.1608106190" id="entry.1608106190">
              <option>Select</option>
              <option value="Accommodation and Food Services">
                Accommodation and Food Services
              </option>
              <option value="Administration, Business Support and Waste Management Services">
                Administration, Business Support and Waste Management Services
              </option>
              <option value="Agriculture, Forestry, Fishing and Hunting">
                Agriculture, Forestry, Fishing and Hunting
              </option>
              <option value="Arts, Entertainment and Recreation">
                Arts, Entertainment and Recreation
              </option>
              <option value="Construction">Construction</option>
              <option value="Educational Services">Educational Services</option>
              <option value="Finance and Insurance">
                Finance and Insurance
              </option>
              <option value="Healthcare and Social Assistance">
                Healthcare and Social Assistance
              </option>
              <option value="Information">Information</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Mining">Mining</option>
              <option value="Other Services (except Public Administration)">
                Other Services (except Public Administration)
              </option>
              <option value="Professional, Scientific and Technical Services">
                Professional, Scientific and Technical Services
              </option>
              <option value="Real Estate and Rental and Leasing">
                Real Estate and Rental and Leasing
              </option>
              <option value="Retail Trade">Retail Trade</option>
              <option value="Transportation and Warehousing">
                Transportation and Warehousing
              </option>
              <option value="Utilities">Utilities</option>
              <option value="Wholesale Trade">Wholesale Trade</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="entry.535045742">Annual Revenue</label>
            <select name="entry.535045742" id="entry.535045742">
              <option></option>
              {revenue.map((rev, index) => (
                <option key={index} value={rev}>
                  {rev}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <button type="submit" className="submit-btn">
              Submit Form
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default JoinBetaForm;
