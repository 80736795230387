import React, { FormEvent } from "react";
import { toast, ToastContainer } from "react-toastify";
import "../../styles/getInTouch.css";
const GetInTouch = () => {
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // This line prevents the default form action
    const form = event.currentTarget;
    const formData = new FormData(event.currentTarget);
    const formDataObj = Object.fromEntries(formData);

    const formBody = new URLSearchParams(formDataObj as any).toString();

    try {
      const response = await fetch(
        "https://docs.google.com/forms/d/e/1FAIpQLSdocKYU_NWlrCeLfTBZeLPOT6djotRIQjCIeKEykMHQ4noD-w/formResponse",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        }
      );

      toast.success(
        "Success! We've received your information. Thank you for reaching out to us, and we'll be in touch soon!"
      );
      // clear the form
      form.reset();
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Submission failed. Please try again.");
    }
  };

  return (
    <div className="getInTouch-wrapper">
      <div className="getInTouch-header">
        <h5>Revolutionize your back office</h5>
        <h3>Get in touch</h3>
        <p>
          The back office solution of the future is here. Try CashZar firsthand,
          and help us improve it by sharing your precious feedback
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        action="https://docs.google.com/forms/d/e/1FAIpQLSdocKYU_NWlrCeLfTBZeLPOT6djotRIQjCIeKEykMHQ4noD-w/formResponse"
        method="POST"
        className="contact-form"
      >
        <div className="form-wrap">
          <div className="getInTouch-fullname">
            <div className="getInTouch-first-name">
              <label htmlFor="entry.1649881937">First Name</label>
              <input
                type="text"
                name="entry.1649881937"
                id="entry.1649881937"
                required
              />
            </div>
            <div className="getInTouch-last-name">
              <label htmlFor="entry.574060310">Last Name</label>
              <input
                type="text"
                name="entry.574060310"
                id="entry.574060310"
                required
              />
            </div>
          </div>
        </div>
        <div className="form-wrap">
          <div className="contact-role">
            <div className="form-email-address">
              <label htmlFor="entry.1913551942">Email address</label>
              <input
                type="text"
                name="entry.1913551942"
                id="entry.1913551942"
                required
              />
            </div>

            <div className="role-wrapper">
              <label htmlFor="entry.337187725">Your Role</label>
              <select name="entry.337187725" id="entry.337187725" required>
                <option>Select</option>
                <option value="CEO">CEO/COO/CFO/CMO</option>
                <option value="CFO">Controller/Accountant/Bookkeeper</option>
                <option value="Controller">Others</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-wrap">
          <label id="entry.2003443652" htmlFor="entry.2003443652">
            I would love to talk with you about:
          </label>
          <textarea
            name="entry.2003443652"
            placeholder="Tell us more"
          ></textarea>
        </div>
        <button className="apply-bottom" type="submit">
          Submit
        </button>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default GetInTouch;

