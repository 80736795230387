import Navbar from "../../components/Navbar";
import styles from "../../styles/Home.module.css";
import Footer from "../../components/Footer";
import MaxEfficenty from "../../components/MaxEfficenty";
import CardList from "../../components/CardList";
import { Highlight } from "../../components/Highlight";
import Steps from "../../components/Step";
import {
  stepsData,
  signupFeature,
  homeHeaderData,
} from "../../assets/app-data";
import { SVGs } from "../../assets/app-svg";
import HeaderLand from "../../components/HeaderLand";
import { HighlightProps } from "../../components/types";
import { ScrollToTopButton } from "../../components/ScrollToTop";
import FutureApps from "../../components/FeatureSlider/FutureApps";

const tailroadPlans: HighlightProps = {
  title: "Stick to what works",
  description: (
    <p>
      Share your process, and let's execute as one seamless team. Engage in a
      conversation with us, and we'll tirelessly ensure consistent, reliable
      delivery. Save your valuable resources time, money, and effort for what
      truly counts<br></br>
      <br></br> Allow us to handle the essentials for you. Our product adeptly
      manages tasks ranging from simple to complex be it bookkeeping,
      accounting, tax, HR, inventory, CRM, and much more. And in moments when it
      faces the unknown, it's designed to learn and adapt. Just name it; our
      solution evolves with your needs
    </p>
  ),
  style: { color: "white", borderLeft: "10px solid #C63E96" },
  svg: SVGs.chess,
};

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <Navbar />
      <div className={styles.wavingHand}>
        <img src="/homeImages/hand.png" alt="Waving hand" />
        <p>Hi Everyone!</p>
      </div>
      <HeaderLand style={{ marginTop: "36px" }} {...homeHeaderData} />
      <ScrollToTopButton />
      <div className={styles.stepHomeWrapper}>

        <div className={styles.stepHomeContainer}>
          <div className={styles.stepHomeHeader}>
            <div className={styles.stepHomeHeaderContent}>
              <div className={styles.stepHomeWrapperHead}>
                <p>Transform your back office and financial management</p>
              </div>
              <h3>Streamline your financial operations with CashZar</h3>
            </div>
            <div className={styles.stepHomeImg}>{SVGs.twoPeople}</div>
          </div>
          <div className={styles.stepHomeSteps}>
            <div className={styles.stepHomeStepIcons}>
              <div>{SVGs.location}</div>
              <div>{SVGs.line}</div>
              <div>{SVGs.location}</div>
              <div>{SVGs.line}</div>
              <div>{SVGs.location}</div>
              <div>{SVGs.line}</div>
              <div>{SVGs.location}</div>
            </div>
            <div className={styles.stepHomeStepsContent}>
              {stepsData.map((step) => (
                <Steps
                  key={step.id}
                  id={step.id}
                  step={step.step}
                  description={step.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Highlight
        background="#25BDBE"
        style={{ color: "white" }}
        {...tailroadPlans}
      />
      <FutureApps />
      <MaxEfficenty
        style={{ borderLeft: "10px solid #E5CA9C", color: "white" }}
        buttonColor="#1C2D5A"
        buttonTextColor="white"
        background="#E0871A"
      />
      <CardList />
      <Highlight style={{ borderLeft: "10px solid #D3AF37" }} {...signupFeature} />
      <Footer />
    </div>
  );
};

export default Home;
