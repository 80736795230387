import Navbar from "../../components/Navbar";
import { Highlight } from "../../components/Highlight";
import Footer from "../../components/Footer";
import HeaderLand from "../../components/HeaderLand";
import { aboutPageHeaderData, signupFeature, benefits , cultures} from "../../assets/app-data";
import BenefitRow from "../../components/BenefitRow";
import styles from '../../styles/Company.module.css';
import CultureCard from "../../components/CultureCard";
import { ScrollToTopButton } from "../../components/ScrollToTop";

const Company = () => {
  return (
    <div className={styles.aboutWrapper}>
      <Navbar/>
      <HeaderLand style={{ marginBottom: "112px"}} {...aboutPageHeaderData}/>
      <ScrollToTopButton />
      <div className={styles.benefitRowWrapper}>
        {benefits.map((row, index) => <BenefitRow key={index} {...row}/>)}
      </div>
      <div className={styles.cultureBlogWrapper}>
        <div className={styles.cultureBlogTop}><h4>Culture blog</h4></div>
        <div className={styles.cultureBlogHead}><h3>Read more about our culture</h3></div>
        <div className={styles.cultureBlogHeadSubtitle}><p>Here are some recent blog posts about our founder, our origins, and our ways of working</p></div>
        <div className={styles.cultureBlogCards}>
          {cultures.map((culture) => 
            <CultureCard key={culture.id} {...culture}/>
          )}
        </div>
      </div>
      
      <Highlight style={{borderLeft: "10px solid #D3AF37"}} {...signupFeature}/>
      <Footer />
    </div>
  )
};

export default Company;
