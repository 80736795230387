import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";

const TreasuryManagement = () => {
  return (
    <div>
      <Navbar />
      <h2>Treasury Management</h2>
      <Footer />
    </div>
  );
};

export default TreasuryManagement;
