import { Link, useLocation } from "react-router-dom";
import styles from "../../styles/Footer.module.css";
import NavButton from "../Buttons/NavButton";
import { SVGs } from "../../assets/app-svg";
import { useEffect, useState } from "react";
import { socialMedia } from "../../assets/app-data";

const Footer = () => {
  const [selected, setSelected] = useState("");
  const location = useLocation();
  
  // Getting the full URL using window.location.origin and the path
  const fullUrl = `${window.location.origin}${location.pathname}`;
  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLogo}>{SVGs.cashzar_white_logo}</div>
      <div className={styles.footerLogoTwo}>{SVGs.cashzar_white_logo}</div>
      <div className={styles.footerSocialWrapper}>
        <div className={styles.footerSocialMedia}>
          <Link to={fullUrl} target="_blank" rel="noopener noreferrer">
            {SVGs.linkIcon}
          </Link>
          <Link to={socialMedia.linkedin} target="_blank" rel="noopener noreferrer">
            {SVGs.linkedin}
          </Link>
          <Link to={socialMedia.youTube} target="_blank" rel="noopener noreferrer" >{SVGs.youtube}</Link>
          <Link to={socialMedia.instagram} target="_blank" rel="noopener noreferrer">
            {SVGs.instagram}
          </Link>
          <Link to={socialMedia.threads} target="_blank" rel="noopener noreferrer">{SVGs.thread}</Link>
        </div>
      </div>
      <div className={styles.footerFirstSection}>
        <div className={styles.footerFirstSectionButtons}>
          <NavButton
            text="Home"
            link="/"
            isFooter={true}
            isSelected={selected === "/"}
          />
          <NavButton
            text="Contact"
            link="/contact"
            isFooter={true}
            isSelected={selected === "/contact"}
          />
          <NavButton
            text="Solutions"
            link="/solution"
            isFooter={true}
            isSelected={selected === "/solution"}
          />
          <NavButton
            text="Join Beta"
            link="/join-beta"
            isFooter={true}
            isSelected={selected === "/join-beta"}
          />
          <NavButton
            text="Resources"
            link="/resources"
            isFooter={true}
            isSelected={selected === "/resources"}
          />
        </div>
      </div>
      <div className={styles.footerSecondSection}>
        <div>
          <p>© 2023 CashZar. All rights reserved.</p>
        </div>
        <div className={styles.footerSecondSectionButtons}>
          <Link to="/policies/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
          <Link to="/policies/terms-and-conditions" target="_blank">
            Terms and Conditions
          </Link>
          <Link to="/policies/cookie-policy" target="_blank">
            Cookie Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
