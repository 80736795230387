import { Link } from "react-router-dom";
import { SVGs } from "../../assets/app-svg";
import "../../styles/card.css";

interface CardProps {
  id: number;
  img: string;
  category: string;
  title: string;
  description: string;
  link: string;
}
const Card = ({ id, img, category, title, description, link }: CardProps) => {
  return (
    <div key={id} className="card-container">
      <Link style={{textDecorationLine: "none"}} to={link}>
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="card-content-wrapper">
        <div className="card-content">
          <div className="card-content-category">
            <h4>Category</h4>
            
          </div>
          <h3>{title}</h3>
          <p>{description}</p>
          <div className="cardList-learn-more">{SVGs.tinyVector}<Link to={link}>Read more</Link></div>
        </div>
      </div></Link>
    </div>
  );
};

export default Card;
