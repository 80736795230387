import { Link } from "react-router-dom";
import { socialMedia } from "../../assets/app-data";
import { SVGs } from "../../assets/app-svg";
import styles from "../../styles/BlogHeader.module.css";
import { IoIosArrowForward } from "react-icons/io";

interface BlogHeaderProps {
  title: string;
  img: string;
  startPath: string;
  endPath: string;
  link: string;
}

const BlogHeader: React.FC<BlogHeaderProps> = (props) => {
  const { title, img, startPath, endPath , link} = props;
  return (
    <div className={styles.blogHeaderWrapper}>
      <div className={styles.blogHeaderContainer}>
        {endPath && <div className={styles.blogHeaderPath}><Link to={link}><h6 style={{color: "#E0871A", textDecorationLine: "underline", marginRight: "8px"}}>{startPath}</h6></Link><IoIosArrowForward /><h6 style={{color: "#D3AF37", marginLeft: "8px"}}>{endPath}</h6></div>}
        <div className={styles.blogHeaderTitle}>
          <h3>{title}</h3>
        </div>
        <div className={styles.blogHeaderMedia}>
          <div className={styles.blogHeaderBookIcon}>
            <div className={styles.blogIcons}>{SVGs.culture_book}</div>
            <p
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              11 Jan 2024
            </p>
            {/* <p
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {" "}
              . 5 min read
            </p> */}
          </div>
          <div className={styles.blogHeaderMediaIcons}>
            <Link to="" target="_blank" className={styles.blogIcons}>
              {SVGs.linkIcon}
            </Link>
            <Link
              to={socialMedia.linkedin}
              target="_blank"
              className={styles.blogIcons}
            >
              {SVGs.linkedin}
            </Link>
            <Link
              to={socialMedia.youTube}
              target="_blank"
              className={styles.blogIcons}
            >
              {SVGs.youtube}
            </Link>
            <Link
              to={socialMedia.instagram}
              target="_blank"
              className={styles.blogIcons}
            >
              {SVGs.instagram_green}
            </Link>
            <Link
              to={socialMedia.threads}
              target="_blank"
              className={styles.blogIcons}
            >
              {SVGs.thread}
            </Link>
          </div>
        </div>
        <div className={styles.blogHeaderImg}>
          <img src={img} alt={title}></img>
        </div>
      </div>
    </div>
  );
};

export default BlogHeader;
