import { Highlight } from "../Highlight";
import { questions } from "../../assets/app-data";
import React, { useState } from "react";
import "../../styles/FAQ.css";
import faqs from "../../assets/faqs.json";
import { FAQItem } from "./FAQItem";
import BaseButton from "../Buttons/BaseButton";
import FeedbackPopup from "../FeedbackPopup";



interface FAQListProps {
}

const FAQList: React.FC<FAQListProps> = () => {
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  const togglePopup = () => {
    setShowFeedbackPopup(!showFeedbackPopup);
  };
  return (
    <div className="questions-wrapper">
      <Highlight style={{borderLeft: "10px solid #D3AF37"}} {...questions} />
      <div className="faq-list">
        <hr></hr>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <div className="contact-us-wrapper">
        <h3>Still have questions?</h3>
        <p>Contact us for further assistance</p>
        <BaseButton text="Contact Us"  background="#E0871A" color="white" onClick={togglePopup} />
      </div>
      <FeedbackPopup show={showFeedbackPopup} onClose={togglePopup} />
    </div>
  );
};

export default FAQList;
